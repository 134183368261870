<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/download-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="8"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="8"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="container">
      <!-- 标题部分开始 -->
      <div class="title">
        {{ $t("message.downloadTitle1") }}
        <span class="small"></span>
        <span class="big"></span>
      </div>
      <!-- 标题部分结束 -->
      <!-- 布展须知开始 -->
      <div class="notice">
        <!-- 标题开始 -->
        <div class="sub-title1">
          <span></span>
          {{ $t("message.downloadTitle2") }}
        </div>
        <!-- 标题结束 -->
        <!-- 单一内容开始 -->
        <div class="download-item" v-for="(item, index) in notice" :key="index">
          <div class="download-item-left">
            <span>*</span>
            <div v-show="language == 'cn'">{{ item.name }}</div>
            <div v-show="language == 'en'">
              {{ item.enName }}
            </div>
          </div>
          <div
            class="download-item-right"
            @click="handleDownload(item.id, item.path)"
          >
            <div class="download-item-right-msg">
              {{ $t("message.download") }}
            </div>
            <div class="download-item-right-img">
              <img src="../assets/svg/download-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
      </div>
      <!-- 布展须知结束 -->
      <!-- 全馆平面图开始 -->
      <div class="whole">
        <!-- 标题开始 -->
        <div class="sub-title1">
          <span></span>
          {{ $t("message.downloadTitle3") }}
        </div>
        <!-- 标题结束 -->
        <!-- 单一内容开始 -->
        <div class="download-item" v-for="(item, index) in whole" :key="index">
          <div class="download-item-left">
            <div class="download-item-left-number">{{ index + 1 }}</div>
            <div class="download-item-left-msg" v-show="language == 'cn'">
              {{ item.name }}
            </div>
            <div class="download-item-left-msg" v-show="language == 'en'">
              {{ item.enName }}
            </div>
          </div>
          <div
            class="download-item-right"
            @click="handleDownload(item.id, item.path)"
          >
            <div class="download-item-right-msg">
              {{ $t("message.download") }}
            </div>
            <div class="download-item-right-img">
              <img src="../assets/svg/download-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
      </div>
      <!-- 全馆平面图结束 -->
      <!-- 单馆平面图开始 -->
      <div class="single">
        <!-- 标题开始 -->
        <div class="sub-title1">
          <span></span>
          {{ $t("message.downloadTitle4") }}
        </div>
        <!-- 标题结束 -->
        <!-- 单一内容开始 -->
        <div class="download-item" v-for="(item, index) in single" :key="index">
          <div class="download-item-left">
            <div class="download-item-left-number">{{ index + 1 }}</div>
            <div class="download-item-left-msg" v-show="language == 'cn'">
              {{ item.name }}
            </div>
            <div class="download-item-left-msg" v-show="language == 'en'">
              {{ item.enName }}
            </div>
          </div>
          <div
            class="download-item-right"
            @click="handleDownload(item.id, item.path)"
          >
            <div class="download-item-right-msg">
              {{ $t("message.download") }}
            </div>
            <div class="download-item-right-img">
              <img src="../assets/svg/download-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <!-- 单一内容结束 -->
      </div>
      <!-- 单馆平面图结束 -->
    </div>
    <!-- 内容结束 -->
    <!-- <el-dialog
      :visible.sync="centerDialogVisible"
      width="80%"
      center
      top="30vh"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">
        <div v-show="language == 'cn'">提示</div>
        <div v-show="language == 'en'">Tips</div>
      </span>
      <div class="dialog-msg" v-show="language == 'cn'">
        如需下载请使用笔记本，或者Android手机
      </div>
      <div class="dialog-msg" v-show="language == 'en'">
        To download, please use a PC or Android phone
      </div>
      <span slot="footer" class="dialog-footer">
        <div v-show="language == 'cn'">
          <el-button @click="centerDialogVisible = false">关 闭</el-button>
        </div>
        <div v-show="language == 'en'">
          <el-button @click="centerDialogVisible = false">Close</el-button>
        </div>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      notice: [], //办展须知
      whole: [], //全馆平面图
      single: [], //单馆平面图
      centerDialogVisible: false,
    };
  },
  mounted() {
    this.$axios.get("api/uploadList?type=1&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.notice = res.data.data;
      }
    });
    this.$axios.get("api/uploadList?type=2&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.whole = res.data.data;
      }
    });
    this.$axios.get("api/uploadList?type=3&state=1", {}).then((res) => {
      if (res.data.code == 0) {
        this.single = res.data.data;
      }
    });
  },
  methods: {
    handleDownload(value, name) {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        // this.centerDialogVisible = true;
        if (this.language == "cn") {
          this.$message({
            message: "如需下载请使用笔记本，或者Android手机",
            type: "warning",
            center: true,
            offset: 400,
            duration: 2000,
            showClose: true,
          });
        }
        if (this.language == "en") {
          this.$message({
            message: "To download, please use a PC or Android phone",
            type: "warning",
            center: true,
            offset: 400,
            duration: 2000,
            showClose: true,
          });
        }
      } else {
        this.$axios.get(`api/getUpload?id=${value}`, {}).then((res) => {
          let arr = name.split("/");
          const fileName = arr.slice(-1)[0];
          // const fileName = name;
          if ("download" in document.createElement("a")) {
            // 支持a标签download的浏览器
            const link = document.createElement("a"); // 创建a标签
            link.download = fileName; // a标签添加属性
            link.style.display = "none";
            link.target = "_blank";
            link.href =
              res.data.data.path +
              "?response-content-type=application/octet-stream";
            document.body.appendChild(link);
            link.click(); // 执行下载
            URL.revokeObjectURL(link.href); // 释放url
            document.body.removeChild(link); // 释放标签
          } else {
            // 其他浏览器
            navigator.msSaveBlob(blob, fileName);
          }
        });
      }
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.notice {
  margin: 40px 0;
}

.download-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-item-left {
  color: #333333;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
  display: flex;
  align-items: center;
}

.download-item-left span {
  color: #ff0000;
  margin-right: 4px;
}

.download-item-left-number {
  width: 24px;
  height: 24px;
  color: #004bc7;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  background-color: #eaf1fd;
  line-height: 24px;
  margin-right: 16px;
}

.download-item-right {
  background-color: #1691fa;
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  align-items: center;
}

.download-item-right:hover {
  background-color: #0152d9;
}

.download-item-right-msg {
  color: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  margin-right: 12px;
}

.whole,
.single {
  margin: 80px 0;
}

.dialog-msg {
  word-break: normal;
  line-height: 30px;
}
</style>
